import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'photoswipe/dist/photoswipe.css';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import { AuthProvider, CompanyProvider } from './firebase/auth';
import { SnackbarProvider } from './utils/SnackbarContext';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette : {
        primary: {
            50: "#ececfe",
            100: "#dad8fd",
            200: "#c7c5fc",
            300: "#8f8bfa",
            400: "#6964f8",
            500: "#443df6",
            600: "#3d37dd",
            700: "#292594",
            800: "#221f7b",
            900: "#14124a",
            plainColor: "var(--joy-palette-primary-500, #0B6BCB)",
            plainHoverBg: "var(--joy-palette-primary-100, #E3EFFB)",
            plainActiveBg: "var(--joy-palette-primary-200, #C7DFF7)",
            plainDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedColor: "var(--joy-palette-primary-500, #0B6BCB)",
            outlinedBorder: "var(--joy-palette-primary-300, #97C3F0)",
            outlinedHoverBg: "var(--joy-palette-primary-100, #E3EFFB)",
            outlinedActiveBg: "var(--joy-palette-primary-200, #C7DFF7)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200, #DDE7EE)",
            softColor: "var(--joy-palette-primary-700, #12467B)",
            softBg: "var(--joy-palette-primary-100, #E3EFFB)",
            softHoverBg: "var(--joy-palette-primary-200, #C7DFF7)",
            softActiveColor: "var(--joy-palette-primary-800, #0A2744)",
            softActiveBg: "var(--joy-palette-primary-300, #97C3F0)",
            softDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            softDisabledBg: "var(--joy-palette-neutral-50, #FBFCFE)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-primary-500, #0B6BCB)",
            solidHoverBg: "var(--joy-palette-primary-600, #185EA5)",
            solidActiveBg: "var(--joy-palette-primary-700, #12467B)",
            solidDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            solidDisabledBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            mainChannel: "68 61 246",
            lightChannel: "199 197 252",
            darkChannel: "41 37 148",
        },
        neutral: {
            50: "#FBFCFE",
            100: "#F0F4F8",
            200: "#DDE7EE",
            300: "#CDD7E1",
            400: "#9FA6AD",
            500: "#636B74",
            600: "#555E68",
            700: "#32383E",
            800: "#171A1C",
            900: "#0B0D0E",
            950: "#090a0b",
            plainColor: "var(--joy-palette-neutral-700, #32383E)",
            plainHoverBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            plainActiveBg: "var(--joy-palette-neutral-200, #DDE7EE)",
            plainDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedColor: "var(--joy-palette-neutral-700, #32383E)",
            outlinedBorder: "var(--joy-palette-neutral-200)",
            outlinedHoverBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            outlinedActiveBg: "var(--joy-palette-neutral-200, #DDE7EE)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200, #DDE7EE)",
            softColor: "var(--joy-palette-neutral-700, #32383E)",
            softBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            softHoverBg: "var(--joy-palette-neutral-200, #DDE7EE)",
            softActiveColor: "var(--joy-palette-neutral-800, #171A1C)",
            softActiveBg: "var(--joy-palette-neutral-300, #CDD7E1)",
            softDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            softDisabledBg: "var(--joy-palette-neutral-50, #FBFCFE)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-neutral-500, #636B74)",
            solidHoverBg: "var(--joy-palette-neutral-600, #555E68)",
            solidActiveBg: "var(--joy-palette-neutral-700, #32383E)",
            solidDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            solidDisabledBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            plainHoverColor: "var(--joy-palette-neutral-900, #0B0D0E)",
            mainChannel: "99 107 116",
            lightChannel: "221 231 238",
            darkChannel: "50 56 62",
        },
        danger: {
            50: "#fef2f2",
            100: "#ffe1e1",
            200: "#ffc9c9",
            300: "#fea3a3",
            400: "#fb6e6e",
            500: "#f23a3a",
            600: "#e02222",
            700: "#bc1919",
            800: "#9c1818",
            900: "#811b1b",
            plainColor: "var(--joy-palette-danger-500, #C41C1C)",
            plainHoverBg: "var(--joy-palette-danger-100, #FCE4E4)",
            plainActiveBg: "var(--joy-palette-danger-200, #F7C5C5)",
            plainDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedColor: "var(--joy-palette-danger-500, #C41C1C)",
            outlinedBorder: "var(--joy-palette-danger-300, #F09898)",
            outlinedHoverBg: "var(--joy-palette-danger-100, #FCE4E4)",
            outlinedActiveBg: "var(--joy-palette-danger-200, #F7C5C5)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200, #DDE7EE)",
            softColor: "var(--joy-palette-danger-700, #7D1212)",
            softBg: "var(--joy-palette-danger-100, #FCE4E4)",
            softHoverBg: "var(--joy-palette-danger-200, #F7C5C5)",
            softActiveColor: "var(--joy-palette-danger-800, #430A0A)",
            softActiveBg: "var(--joy-palette-danger-300, #F09898)",
            softDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            softDisabledBg: "var(--joy-palette-danger-50, #FBFCFE)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-danger-500, #C41C1C)",
            solidHoverBg: "var(--joy-palette-danger-600, #A51818)",
            solidActiveBg: "var(--joy-palette-danger-700, #7D1212)",
            solidDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            solidDisabledBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            mainChannel: "242 58 58",
            lightChannel: "255 201 201",
            darkChannel: "188 25 25",
        },
        success: {
            50: "#F6FEF6",
            100: "#E3FBE3",
            200: "#C7F7C7",
            300: "#A1E8A1",
            400: "#51BC51",
            500: "#1F7A1F",
            600: "#136C13",
            700: "#0A470A",
            800: "#042F04",
            900: "#021D02",
            plainColor: "var(--joy-palette-success-500, #1F7A1F)",
            plainHoverBg: "var(--joy-palette-success-100, #E3FBE3)",
            plainActiveBg: "var(--joy-palette-success-200, #C7F7C7)",
            plainDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedColor: "var(--joy-palette-success-500, #1F7A1F)",
            outlinedBorder: "var(--joy-palette-success-300, #A1E8A1)",
            outlinedHoverBg: "var(--joy-palette-success-100, #E3FBE3)",
            outlinedActiveBg: "var(--joy-palette-success-200, #C7F7C7)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200, #DDE7EE)",
            softColor: "var(--joy-palette-success-700, #0A470A)",
            softBg: "var(--joy-palette-success-100, #E3FBE3)",
            softHoverBg: "var(--joy-palette-success-200, #C7F7C7)",
            softActiveColor: "var(--joy-palette-success-800, #042F04)",
            softActiveBg: "var(--joy-palette-success-300, #A1E8A1)",
            softDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            softDisabledBg: "var(--joy-palette-neutral-50, #FBFCFE)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-success-500, #1F7A1F)",
            solidHoverBg: "var(--joy-palette-success-600, #136C13)",
            solidActiveBg: "var(--joy-palette-success-700, #0A470A)",
            solidDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            solidDisabledBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            mainChannel: "31 122 31",
            lightChannel: "199 247 199",
            darkChannel: "10 71 10",
        },
        warning: {
            50: "#fffaeb",
            100: "#fff2c6",
            200: "#ffe288",
            300: "#ffce4a",
            400: "#ffbb29",
            500: "#f99607",
            600: "#dd6f02",
            700: "#b74c06",
            800: "#943a0c",
            900: "#7a300d",
            plainColor: "var(--joy-palette-warning-500, #9A5B13)",
            plainHoverBg: "var(--joy-palette-warning-100, #FDF0E1)",
            plainActiveBg: "var(--joy-palette-warning-200, #FCE1C2)",
            plainDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedColor: "var(--joy-palette-warning-500, #9A5B13)",
            outlinedBorder: "var(--joy-palette-warning-300, #F3C896)",
            outlinedHoverBg: "var(--joy-palette-warning-100, #FDF0E1)",
            outlinedActiveBg: "var(--joy-palette-warning-200, #FCE1C2)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200, #DDE7EE)",
            softColor: "var(--joy-palette-warning-700, #492B08)",
            softBg: "var(--joy-palette-warning-100, #FDF0E1)",
            softHoverBg: "var(--joy-palette-warning-200, #FCE1C2)",
            softActiveColor: "var(--joy-palette-warning-800, #2E1B05)",
            softActiveBg: "var(--joy-palette-warning-300, #F3C896)",
            softDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            softDisabledBg: "var(--joy-palette-warning-50, #FBFCFE)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-warning-500, #9A5B13)",
            solidHoverBg: "var(--joy-palette-warning-600, #72430D)",
            solidActiveBg: "var(--joy-palette-warning-700, #492B08)",
            solidDisabledColor: "var(--joy-palette-neutral-400, #9FA6AD)",
            solidDisabledBg: "var(--joy-palette-neutral-100, #F0F4F8)",
            mainChannel: "249 150 7",
            lightChannel: "255 226 136",
            darkChannel: "183 76 6",
        },
        common: {
            white: "#ffffff",
            black: "#000000",
        },
        text: {
            primary: "var(--joy-palette-neutral-900)",
            secondary: "var(--joy-palette-neutral-700)",
            tertiary: "var(--joy-palette-neutral-500)",
            icon: "var(--joy-palette-neutral-500, #636B74)",
        },
        background: {
            body: "var(--joy-palette-common-white)",
            surface: "var(--joy-palette-common-white)",
            popup: "var(--joy-palette-common-white, #FFF)",
            level1: "var(--joy-palette-neutral-50)",
            level2: "var(--joy-palette-neutral-100)",
            level3: "var(--joy-palette-neutral-200)",
            tooltip: "var(--joy-palette-neutral-500, #636B74)",
            backdrop: "rgba(9, 10, 11, 0.8)",
        },
        divider: "var(--joy-palette-neutral-200)",
        focusVisible: "var(--joy-palette-primary-500, #0B6BCB)",
        gradient: {
            1: "linear-gradient(120deg, #eefadc 0%, #fce5f3 100%)",
            2: "linear-gradient(120deg, #cee7fe 0%, #eefadc 100%)",
            3: "linear-gradient(120deg, #f9d8e7 0%, #cee7fe 100%)",
            4: "linear-gradient(120deg, #c6d4f9 0%, #f9d8e7 100%)",
        },
        shadow: {
            ring: "0 0 #000",
            channel: "21 21 21",
            opacity: "0.04",
        },
      }
    },
    dark: {
      palette : {
        primary: {
            50: "#ececfe",
            100: "#dad8fd",
            200: "#c7c5fc",
            300: "#8f8bfa",
            400: "#6964f8",
            500: "#443df6",
            600: "#3d37dd",
            700: "#292594",
            800: "#221f7b",
            900: "#14124a",
            plainColor: "var(--joy-palette-primary-300, #97C3F0)",
            plainHoverBg: "var(--joy-palette-primary-800, #0A2744)",
            plainActiveBg: "var(--joy-palette-primary-700, #12467B)",
            plainDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedColor: "var(--joy-palette-primary-200, #C7DFF7)",
            outlinedBorder: "var(--joy-palette-primary-700, #12467B)",
            outlinedHoverBg: "var(--joy-palette-primary-800, #0A2744)",
            outlinedActiveBg: "var(--joy-palette-primary-700, #12467B)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800, #171A1C)",
            softColor: "var(--joy-palette-primary-200, #C7DFF7)",
            softBg: "var(--joy-palette-primary-800, #0A2744)",
            softHoverBg: "var(--joy-palette-primary-700, #12467B)",
            softActiveColor: "var(--joy-palette-primary-100, #E3EFFB)",
            softActiveBg: "var(--joy-palette-primary-600, #185EA5)",
            softDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            softDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-primary-500, #0B6BCB)",
            solidHoverBg: "var(--joy-palette-primary-600, #185EA5)",
            solidActiveBg: "var(--joy-palette-primary-700, #12467B)",
            solidDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            solidDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            mainChannel: "105 100 248",
            lightChannel: "199 197 252",
            darkChannel: "41 37 148",
        },
        neutral: {
            50: "#FBFCFE",
            100: "#F0F4F8",
            200: "#DDE7EE",
            300: "#CDD7E1",
            400: "#9FA6AD",
            500: "#636B74",
            600: "#555E68",
            700: "#32383E",
            800: "#171A1C",
            900: "#0B0D0E",
            950: "#090a0b",
            plainColor: "var(--joy-palette-neutral-300, #CDD7E1)",
            plainHoverBg: "var(--joy-palette-neutral-800, #171A1C)",
            plainActiveBg: "var(--joy-palette-neutral-700, #32383E)",
            plainDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedColor: "var(--joy-palette-neutral-200, #DDE7EE)",
            outlinedBorder: "var(--joy-palette-neutral-700)",
            outlinedHoverBg: "var(--joy-palette-neutral-800, #171A1C)",
            outlinedActiveBg: "var(--joy-palette-neutral-700, #32383E)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800, #171A1C)",
            softColor: "var(--joy-palette-neutral-200, #DDE7EE)",
            softBg: "var(--joy-palette-neutral-800, #171A1C)",
            softHoverBg: "var(--joy-palette-neutral-700, #32383E)",
            softActiveColor: "var(--joy-palette-neutral-100, #F0F4F8)",
            softActiveBg: "var(--joy-palette-neutral-600, #555E68)",
            softDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            softDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-neutral-500, #636B74)",
            solidHoverBg: "var(--joy-palette-neutral-600, #555E68)",
            solidActiveBg: "var(--joy-palette-neutral-700, #32383E)",
            solidDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            solidDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            plainHoverColor: "var(--joy-palette-neutral-300, #CDD7E1)",
            mainChannel: "159 166 173",
            lightChannel: "221 231 238",
            darkChannel: "50 56 62",
        },
        danger: {
            50: "#fef2f2",
            100: "#ffe1e1",
            200: "#ffc9c9",
            300: "#fea3a3",
            400: "#fb6e6e",
            500: "#f23a3a",
            600: "#e02222",
            700: "#bc1919",
            800: "#9c1818",
            900: "#811b1b",
            plainColor: "var(--joy-palette-danger-300, #F09898)",
            plainHoverBg: "var(--joy-palette-danger-800, #430A0A)",
            plainActiveBg: "var(--joy-palette-danger-700, #7D1212)",
            plainDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedColor: "var(--joy-palette-danger-200, #F7C5C5)",
            outlinedBorder: "var(--joy-palette-danger-700, #7D1212)",
            outlinedHoverBg: "var(--joy-palette-danger-800, #430A0A)",
            outlinedActiveBg: "var(--joy-palette-danger-700, #7D1212)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800, #171A1C)",
            softColor: "var(--joy-palette-danger-200, #F7C5C5)",
            softBg: "var(--joy-palette-danger-800, #430A0A)",
            softHoverBg: "var(--joy-palette-danger-700, #7D1212)",
            softActiveColor: "var(--joy-palette-danger-100, #FCE4E4)",
            softActiveBg: "var(--joy-palette-danger-600, #A51818)",
            softDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            softDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-danger-500, #C41C1C)",
            solidHoverBg: "var(--joy-palette-danger-600, #A51818)",
            solidActiveBg: "var(--joy-palette-danger-700, #7D1212)",
            solidDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            solidDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            mainChannel: "251 110 110",
            lightChannel: "255 201 201",
            darkChannel: "188 25 25",
        },
        success: {
            50: "#f0fdf2",
            100: "#dcfce3",
            200: "#bbf7c9",
            300: "#87eea0",
            400: "#4bdd6f",
            500: "#25d050",
            600: "#17a23a",
            700: "#167f31",
            800: "#16652b",
            900: "#145326",
            plainColor: "var(--joy-palette-success-300, #A1E8A1)",
            plainHoverBg: "var(--joy-palette-success-800, #042F04)",
            plainActiveBg: "var(--joy-palette-success-700, #0A470A)",
            plainDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedColor: "var(--joy-palette-success-200, #C7F7C7)",
            outlinedBorder: "var(--joy-palette-success-700, #0A470A)",
            outlinedHoverBg: "var(--joy-palette-success-800, #042F04)",
            outlinedActiveBg: "var(--joy-palette-success-700, #0A470A)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800, #171A1C)",
            softColor: "var(--joy-palette-success-200, #C7F7C7)",
            softBg: "var(--joy-palette-success-800, #042F04)",
            softHoverBg: "var(--joy-palette-success-700, #0A470A)",
            softActiveColor: "var(--joy-palette-success-100, #E3FBE3)",
            softActiveBg: "var(--joy-palette-success-600, #136C13)",
            softDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            softDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-success-500, #1F7A1F)",
            solidHoverBg: "var(--joy-palette-success-600, #136C13)",
            solidActiveBg: "var(--joy-palette-success-700, #0A470A)",
            solidDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            solidDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            mainChannel: "75 221 111",
            lightChannel: "187 247 201",
            darkChannel: "22 127 49",
        },
        warning: {
            50: "#fffaeb",
            100: "#fff2c6",
            200: "#ffe288",
            300: "#ffce4a",
            400: "#ffbb29",
            500: "#f99607",
            600: "#dd6f02",
            700: "#b74c06",
            800: "#943a0c",
            900: "#7a300d",
            plainColor: "var(--joy-palette-warning-300, #F3C896)",
            plainHoverBg: "var(--joy-palette-warning-800, #2E1B05)",
            plainActiveBg: "var(--joy-palette-warning-700, #492B08)",
            plainDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedColor: "var(--joy-palette-warning-200, #FCE1C2)",
            outlinedBorder: "var(--joy-palette-warning-700, #492B08)",
            outlinedHoverBg: "var(--joy-palette-warning-800, #2E1B05)",
            outlinedActiveBg: "var(--joy-palette-warning-700, #492B08)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800, #171A1C)",
            softColor: "var(--joy-palette-warning-200, #FCE1C2)",
            softBg: "var(--joy-palette-warning-800, #2E1B05)",
            softHoverBg: "var(--joy-palette-warning-700, #492B08)",
            softActiveColor: "var(--joy-palette-warning-100, #FDF0E1)",
            softActiveBg: "var(--joy-palette-warning-600, #72430D)",
            softDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            softDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            solidColor: "var(--joy-palette-common-white, #FFF)",
            solidBg: "var(--joy-palette-warning-500, #9A5B13)",
            solidHoverBg: "var(--joy-palette-warning-600, #72430D)",
            solidActiveBg: "var(--joy-palette-warning-700, #492B08)",
            solidDisabledColor: "var(--joy-palette-neutral-500, #636B74)",
            solidDisabledBg: "var(--joy-palette-neutral-800, #171A1C)",
            mainChannel: "255 187 41",
            lightChannel: "255 226 136",
            darkChannel: "183 76 6",
        },
        common: {
            white: "#ffffff",
            black: "#000000",
        },
        text: {
            primary: "var(--joy-palette-common-white)",
            secondary: "var(--joy-palette-neutral-200)",
            tertiary: "var(--joy-palette-neutral-500)",
            icon: "var(--joy-palette-neutral-400, #9FA6AD)",
        },
        background: {
            body: "var(--joy-palette-neutral-900)",
            surface: "var(--joy-palette-neutral-900)",
            popup: "var(--joy-palette-common-black, #000)",
            level1: "var(--joy-palette-neutral-800)",
            level2: "var(--joy-palette-neutral-700)",
            level3: "var(--joy-palette-neutral-600)",
            tooltip: "var(--joy-palette-neutral-600, #555E68)",
            backdrop: "rgba(9, 10, 11, 0.9)",
        },
        divider: "var(--joy-palette-neutral-700)",
        focusVisible: "var(--joy-palette-primary-500, #0B6BCB)",
        gradient: {
            1: "linear-gradient(120deg, #eefadc 0%, #fce5f3 100%)",
            2: "linear-gradient(120deg, #cee7fe 0%, #eefadc 100%)",
            3: "linear-gradient(120deg, #f9d8e7 0%, #cee7fe 100%)",
            4: "linear-gradient(120deg, #c6d4f9 0%, #f9d8e7 100%)",
        },
        shadow: {
            ring: "0 0 #000",
            channel: "0 0 0",
            opacity: "0.3",
        },
      }    
    }
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <CompanyProvider>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </CompanyProvider>  
      </AuthProvider>
    </CssVarsProvider>
  </React.StrictMode>
);